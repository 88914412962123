.container {
    display: flex;
    gap: 16px;
    justify-content: center;
    width: 100%;
}

.text {
    width: 315px;
}

.container img {
    width: 36px;
}
