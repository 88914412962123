/* apply any border-radius and any right, left or bottom border for the table at the bottom of the pie chart */

.table :global(section.table-section .table-container) {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    border-radius: 0 0 4px 4px;
}

.big-container :global(figure.spinner) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.group-block {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: stretch;
    width: 100%;
}

.charts-group,
.reports-block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 450px), 1fr));
    column-gap: 16px;
    width: 100%;
}

.reports-block :global(article.card-block) {
    width: 100%;
}

.report-chart {
    box-shadow: inset 0 2px 2px rgba(0 0 0 / 40%), inset 0 -2px 2px rgba(0 0 0 / 40%);
}

.chart {
    width: 100%;
    height: 100%;
    min-height: 80px;
    border-collapse: collapse;
    border: 1px solid rgb(196 196 196);
    border-radius: 4px;
}

.chart th {
    padding: 8px;
    text-align: left;
    background-color: rgb(239 239 239);
}

.chart td > div {
    display: flex;
    gap: 0;
    height: 24px;
    padding: 0;
    margin: 0;
}

.chart-legend {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
}

.legend-sold,
.legend-prepaid,
.legend-preregistered {
    display: flex;
    align-items: center;
    width: 150px;
    height: 24px;
    padding: 4px 8px;
    border-radius: 4px;
}

.legend-sold {
    background-color: rgba(18 135 79 / 60%);
    border: 2px solid rgb(18 135 79);
}

.legend-prepaid {
    background-color: rgba(25 117 186 / 60%);
    border: 2px solid rgb(25 117 186);
}

.legend-preregistered {
    background-color: rgba(109 102 97 / 60%);
    border: 2px solid rgb(109 102 97);
}

@media only screen and (max-width: 762px) {
    .group-block {
        flex-direction: column;
    }

    .legend-sold,
    .legend-prepaid,
    .legend-preregistered {
        width: 100%;
        text-align: center;
    }
}
