.container :global(.settings a:hover) {
    cursor: pointer;
    background-color: #ddd;
}

.container :global(nav.settings a) {
    font-weight: 600;
    background-color: #ddd6;
}

.container :global(nav.settings a).toggler {
    background-color: #fff;
}

.container :global(nav.settings a.link) {
    display: inline;
    padding: 0;
    text-decoration: underline;
    background: none;
    border: none;
}

.container :global(nav.settings p) {
    color: #000;
}

.list {
    margin-left: 36px;
    list-style-type: disc;
}

.content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 15px;
    animation: fade-in 0.3s ease-in-out;
}

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
