:root {
    --color-ehadj-primary: 0 109 49;
}

.roaster-coffee {
    background-color: #efefef;
}

/* Icons */

span.icon.gray {
    background-color: #d1d1d1;
}

/* Table */

table.datatable td {
    vertical-align: middle;
}

/* Authentication */

aside.auth-decoration {
    background-color: #bfd5ca;
}

aside.auth-decoration .app-logo p {
    line-height: 31px;
}

aside.auth-decoration .shapes.bottom {
    position: absolute;
    right: 0;
    bottom: 0;
}

aside.auth-decoration .breadcrumbs {
    margin-bottom: 28px;
    margin-left: 64px;
}

aside.auth-decoration .app-logo img,
nav.appnav .app-logo img {
    max-width: 103px;
}

.auth-form-container {
    margin: 100px auto;
}

.auth-form-container .button {
    width: auto;
    max-width: none;
    margin: 0;
}

.auth-form-container .button.full-width {
    width: 100%;
}

.prefixed-input {
    padding-left: 60px;
}

.stats-container {
    align-items: stretch;
}

nav.appnav .appnav-section.header {
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #d1d1d1;
}

/* color override */
nav.appnav li .menu-item.active,
nav.appnav li .menu-item:active {
    background-color: rgba(var(--color-ehadj-primary) / 20%);
}

nav.appnav li .menu-item.active::after,
figure.loader span.loading-level {
    background-color: rgba(var(--color-ehadj-primary));
}

nav.appnav li .menu-item:hover {
    background-color: rgba(var(--color-ehadj-primary) / 15%);
}

figure.loader {
    background-color: rgba(var(--color-ehadj-primary) / 40%);
}

article.card-stat figure.progress.circular span.progress-level {
    background: conic-gradient(
        rgba(var(--color-ehadj-primary)) calc(var(--percent) * 3.6deg),
        rgba(var(--color-smoke)) 0deg
    );
}

/* add style for sidebar icons */
nav.appnav span.icon i,
nav.appnav span.icon img,
nav.appnav span.icon svg {
    height: 20px;
}

/* small gray icons */
span.icon.small.gray i {
    font-size: 10px;
}

/* change checkbox background color */
form input[type='checkbox']:checked ~ span.input-checkbox {
    background-color: rgba(var(--color-ehadj-primary) / 80%);
}

/* set as minimum height for stats cards */
article.card-stat {
    min-height: 90px;
}

@media only screen and (max-width: 762px) {
    aside.auth-decoration {
        position: initial;
        display: block;
        width: 100%;
        max-width: initial;
        height: 110px;
    }

    aside.auth-decoration + .mainframe {
        margin-left: initial;
    }

    aside.auth-decoration .app-logo {
        position: absolute;
        top: 36px;
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0;
    }

    aside.auth-decoration .app-logo p,
    aside.auth-decoration .shapes.bottom,
    aside.auth-decoration .breadcrumbs {
        display: none;
    }

    section.appnav-opener-mobile {
        background: #efefef;
    }

    section.appnav-opener-mobile .app-logo {
        height: 80%;
    }

    aside.appnav-container {
        background: #fff;
    }

    article.card-stat .fa-regular.fa-circle-info,
    section.table-section .table-container .fa-regular.fa-circle-info {
        display: none;
    }

    table.datatable td.selector,
    table.datatable th.selector,
    section.table-form .table-actions .card-dropdown-container,
    section.table-form .table-meta .tags-container {
        display: block;
    }
}
