:global(nav.appnav .appnav-section.footer) .identity :global(.user) {
    height: 40px;
}

.identity :global(.store-profile) {
    cursor: default;
}

.name {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
}

:global(nav.appnav li) :global(.menu-item).identity:hover,
:global(nav.appnav .appnav-section.footer) .identity :global(.store-profile):hover {
    background-color: transparent;
}

.identity img {
    width: 35px;
    max-width: 100%;
    height: 31px;
    max-height: 100%;
    margin: auto;
}

.sidebar :global(nav.appnav .app-logo) {
    width: 90%;
}

.sidebar :global(nav.appnav .app-logo span) {
    margin-right: 8px;
}

.legals {
    display: flex;
    gap: 4px;
    margin-top: 16px;
}

.legals a {
    font-size: 12px;
}
