.medicalform :global(form span.switch input:checked + figure),
.categoriesHeader :global(form span.switch input:checked + figure) {
    background-color: rgba(var(--color-ehadj-primary) / 70%);
}

.cart-total-group {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
}

.disabled-field {
    pointer-events: none;
    background-color: #efefef;
}

.categoriesModalTrigger {
    display: inline;
    font-weight: 700;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
}

.categoriesHeader {
    display: flex;
    justify-content: space-between;
}

.categoriesHeader form {
    margin-left: 10px;
}

.categoryCardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    margin-top: 20px;
}

.categoryCard {
    align-self: baseline;
    width: calc((100% - 32px) / 3);
    overflow: hidden;
    border: 1px solid #000;
    border-radius: 16px;
}

.categoryCardHeader {
    height: 200px;
    padding: 16px;
    border-bottom: 1px dashed #000;
}

.categoryCardHeader p {
    font-size: 22px;
    font-weight: bold;
}

.categoryCardHeader ul {
    height: 120px;
    margin: 10px 0 0 24px;
}

.categoryCard .headerStandard {
    background-color: #efefef;
}

.categoryCard .headerPremium {
    background-color: #fde69d;
}

.categoryCard .headerElite {
    background-color: #bfd5ca;
}

.categoryCardContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
}

.categoryCardContent strong {
    font-size: 18px;
}

.categoryCardContent .noBold {
    font-weight: normal;
}

@media only screen and (max-width: 480px) {
    .tablerow :global(.items-group) {
        flex-wrap: nowrap;
    }

    .table :global(.items-group.justify) {
        flex-direction: row;
    }

    .table :global(section.table-section .table-actions) {
        height: auto;
    }

    .container :global(section.table-form .table-actions .card-dropdown-container),
    .container :global(section.table-form .table-meta .tags-container) {
        display: block;
    }
}

:global(table.datatable) tr.disabledRow {
    background-color: transparent;
}

:global(form) tr.disabledRow :global(input[type='checkbox']:disabled ~ span.input-checkbox) {
    background-color: rgba(var(--color-smoke));
}

:global(form input[type='checkbox']:disabled ~ span.input-checkbox::after) {
    display: none;
}
