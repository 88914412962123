.container :global(.items-group.wrap.xl) {
    align-items: stretch;
}

.container :global(figure.spinner) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
