.trip-bar-cell {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 24px;
}

.trip-bar-label {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 12px;
    color: white;
    transform: translate(-50%, -50%);
}
