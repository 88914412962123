.container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-width: 480px;
    padding: 20px;
    margin: 20px auto;
    background-color: #fff;
    border: 1px solid rgba(var(--color-grey) / 40%);
    border-radius: 10px;
}

.container button {
    min-width: 100%;
}

.container a,
.legals a {
    font-weight: bold;
    color: rgba(var(--color-info));
}

.container ul {
    margin-left: 24px;
}
